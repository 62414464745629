@import "reset/reset";
@import "partials/fonts";

html {
  min-height: 100%;
}

@font-face {
  font-family: "tt_norms_pro_regular";
  src: url("../fonts/tt_norms_pro_regular-webfont.woff2") format("woff2"),
    url("../fonts/tt_norms_pro_regular-webfont.woff") format("woff"),
    url("../fonts/tt_norms_pro_regular.otf") format("otf");
  font-weight: normal;
  font-style: normal;
}

.sans-serif {
  font-family: "tt_norms_pro_regular", -apple-system, sans-serif, "avenir next",
    avenir, "helvetica neue", helvetica, ubuntu, roboto, noto, "segoe ui", arial,
    sans-serif;
}

.serif {
  font-family: plantin, Plantin MT Pro, georgia, times, serif;
  font-weight: 300;
  font-style: normal;
}

.interstate {
  font-family: interstate, sans-serif;
  font-weight: 500;
  font-style: normal;
}

img {
  max-width: 100%;
}

a,
a:visited,
a:hover,
a:active {
  color: inherit;
  text-decoration: none;
}

button.cta {
  // padding: 9% 15%;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 1px;
  cursor: pointer;
}

button.disabled {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 1px;
  cursor: auto;
}

.main-bg-image {
  background-image: url("../img/bg.jpg");
  background-size: center;
  background-repeat: no-repeat;
}

.container {
  max-width: 1152px;
}

.btn.btn--menu,
.btn.btn--close {
  font-size: 1.4rem;
  color: #00162e;
}

.quoteText {
  font-size: 14px;
  line-height: 18px;
  font-style: italic;
}

p {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}

small {
  font-family: tt norms pro;
  line-height: 18px;
  font-size: 14px;
  font-weight: 400;
}

ol.number {
  li {
    span {
      display: inline;
      border: 1px solid #00162e;
      border-radius: 50%;
      padding: 10px 12px 7px 12px;
    }
  }
}

.browser {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.dot {
  height: 7px;
  width: 7px;
  border-radius: 50%;
  display: inline-block;
}

.w-45 {
  width: 45%;
}

@media screen and (min-width: 30em) {
  .w-45-ns {
    width: 45%;
  }
}

@media screen and (min-width: 30em) and (max-width: 60em) {
  .w-45-m {
    width: 45%;
  }
}

@media screen and (min-width: 60em) {
  .w-45-l {
    width: 45%;
  }
}

.black {
  color: #000;
}

.near-black {
  color: #00162e;
}

.dark-gray {
  color: #555a5f;
}

.mid-gray {
  color: #818181;
}

.gray {
  color: #d8d8d8;
}

.silver {
  color: #e6e6e7;
}

.light-silver {
  color: #f0f0f1;
}

.moon-gray {
  color: pink;
}

.light-gray {
  color: #f5f5f5;
}

.near-white {
  color: #fdfdfd;
}

.white {
  color: #ffffff;
}

.bg-black {
  background-color: #000;
}

.bg-near-black {
  background-color: #00162e;
}

.bg-dark-gray {
  background-color: #555a5f;
}

.bg-mid-gray {
  background-color: #818181;
}

.bg-gray {
  background-color: #d8d8d8;
}

.bg-silver {
  background-color: #e6e6e7;
}

.bg-light-silver {
  background-color: #f0f0f1;
}

.bg-moon-gray {
  background-color: pink;
}

.bg-light-gray {
  background-color: #f5f5f5;
}

.bg-near-white {
  background-color: #fdfdfd;
}

.bg-white {
  background-color: #ffffff;
}

.b--black {
  border-color: #000;
}

.b--near-black {
  border-color: #00162e;
}

.b--dark-gray {
  border-color: #555a5f;
}

.b--mid-gray {
  border-color: #818181;
}

.b--gray {
  border-color: #d8d8d8;
}

.b--silver {
  border-color: #e6e6e7;
}

.b--light-silver {
  border-color: #f0f0f1;
}

.b--moon-gray {
  border-color: pink;
}

.b--light-gray {
  border-color: #f5f5f5;
}

.b--near-white {
  border-color: #fdfdfd;
}

.b--white {
  border-color: #ffffff;
}

.pa3 {
  padding: 1.25rem;
}

.pt3 {
  padding-top: 1.25rem;
}

.pr3 {
  padding-right: 1.25rem;
}

.pb3 {
  padding-bottom: 1.25rem;
}

.pl3 {
  padding-left: 1.25rem;
}

.ma3 {
  margin: 1.25rem;
}

.mt3 {
  margin-top: 1.25rem;
}

.mr3 {
  margin-right: 1.25rem;
}

.mb3 {
  margin-bottom: 1.25rem;
}

.ml3 {
  margin-left: 1.25rem;
}

// DBC Colour Palette

.bg-darkest-green {
  background-color: #0b4436;
}

.bg-light-mint {
  background-color: #deede9;
}

.bg-mint-green {
  background-color: #c5e6df;
}

.bg-tangerine {
  background-color: #e07445;
}

// DCH Colour Palette

.bg-darkest-blue {
  background-color: #1e429b;
}

.bg-cyan {
  background-color: #1bbcef;
}

.bg-light-blue {
  background-color: #d9f3ff;
}

// MO Colour Palette

.bg-midnight-dark-blue {
  background-color: #114b5f;
}

.bg-sonic-siver {
  background-color: #717171;
}

.bg-silver-chalice {
  background-color: #a8a8a8;
}

.bg-mo-turquoise {
  background-color: #1fd3b8;
}

// WOW

/* Buttons */

.btn {
  border: 0;
  padding: 0;
  margin: 0;
  background: none;
  color: inherit;
}

.btn:focus {
  outline: none;
}

.btn--trigger {
  margin: 1em;
  display: block;
  white-space: nowrap;
}

.btn--default,
.btn--trigger::before {
  font-weight: bold;
  border: 2px solid;
  text-align: center;
  text-indent: 0;
  display: inline-block;
  line-height: 1.7;
}

.btn--default {
  padding: 0 1em;
  margin: 2vh 0 0;
}

.btn--tool {
  display: block;
  margin: 1em 0;
  font-size: 1.3em;
  color: #8a7c78;
}

.navbar-header {
  position: absolute;
  top: 1vw;
  left: 1vw;
  color: #252525;
  padding: 15px 15px;
  pointer-events: visible;
  z-index: 20;
}

.navbar-brand {
  height: auto;
  padding: 0px;
}

.navbar-header svg {
  width: 200px;
  height: 20px;
}

.btn--menu {
  // position: absolute;
  // top: 1vw;
  // right: 2vw;
  color: #252525;
  padding: 15px 0px;
  pointer-events: visible;
  z-index: 10;
}

.btn--close {
  // position: absolute;
  // top: 1vw;
  // right: 2vw;
  position: relative;
  padding: 15px 0px;
  font-size: 0.75em;
  z-index: 100;
  right: 22px;
}

/* Menu */

.menu {
  position: inherit !important;
  z-index: 100;
  width: 0px;
}

.js .menu {
  pointer-events: none;
}

.js .menu--open {
  pointer-events: visible;
}

.menu__inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  list-style-type: none;
  padding: 10vh 3em;
  margin: 0;
  background: #f7f7f7;
  overflow: hidden;
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
  outline: none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.js .menu__item {
  transform: translate3d(-13em, 0, 0);
  transition: transform 0s 0.4s;
}

.la-times {
  fill: #252525;
}

.la-times:hover {
  fill: #484848;
  -webkit-transition: color 400ms ease-in-out;
  -moz-transition: color 400ms ease-in-out;
  -o-transition: color 400ms ease-in-out;
  -ms-transition: color 400ms ease-in-out;
  transition: color 400ms ease-in-out;
}

.btn--close {
  opacity: 0;
}

.menu__item {
  padding: 0.15em;
  text-align: center;
}

.menu--open .menu__item,
.menu--open .btn--close {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: opacity 0.4s 0.135s,
    transform 0.4s 0.135s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.menu--open .menu__item:nth-child(2) {
  transition-delay: 0.165s;
}

.menu--open .menu__item:nth-child(3) {
  transition-delay: 0.195s;
}

.menu--open .menu__item:nth-child(4) {
  transition-delay: 0.225s;
}

.menu .menu__link:hover,
.menu .menu__link:focus {
  outline: none;
  color: #484848;
  -webkit-transition: color 400ms ease-in-out;
  -moz-transition: color 400ms ease-in-out;
  -o-transition: color 400ms ease-in-out;
  -ms-transition: color 400ms ease-in-out;
  transition: color 400ms ease-in-out;
  text-decoration: none;
}

.block-revealer__element {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  pointer-events: none;
  opacity: 0;
}

// animations

.paint,
.cheese,
.book {
  animation: hoveranimation 5s ease-in-out infinite;
}

.ghost,
.sneaker,
.map {
  animation: hoveranimation2 6s ease infinite;
}

.parrot,
.tent,
.camera {
  animation: hoveranimation3 4s ease-in-out infinite;
}

.plant,
.doughnut,
.dragon,
.fantasy {
  animation: hoveranimation4 7s ease-in-out infinite;
}

@keyframes hoveranimation {
  0% {
    transform: translate(1px, 5px);
  }
  50% {
    transform: translate(1px, 10px);
  }
  100% {
    transform: translate(1px, 5px);
  }
}

@keyframes hoveranimation2 {
  0% {
    transform: translate(1px, 20px);
    transform: rotate(10deg);
  }
  50% {
    transform: translate(1px, 28px);
    transform: rotate(20deg);
  }
  100% {
    transform: translate(1px, 20px);
    transform: rotate(10deg);
  }
}

@keyframes hoveranimation3 {
  0% {
    transform: translate(1px, 1px) rotate(5deg);
  }
  50% {
    transform: translate(1px, 6px) rotate(5deg);
  }
  100% {
    transform: translate(1px, 1px) rotate(5deg);
  }
}

@keyframes hoveranimation4 {
  0% {
    transform: translate(1px, 20px) rotate(10deg);
  }
  50% {
    transform: translate(1px, 29px) rotate(10deg);
  }
  100% {
    transform: translate(1px, 20px) rotate(10deg);
  }
}

.wave {
  animation: waveanimation 2s ease-out infinite;
}

@keyframes waveanimation {
  0% {
    transform: translate(190px, 20px);
    transform: rotate(10deg);
  }
  10% {
    transform: translate(495px, 32px);
    transform: rotate(40deg);
  }
  20% {
    transform: translate(190px, 20px);
    transform: rotate(10deg);
  }
  30% {
    transform: translate(495px, 32px);
    transform: rotate(40deg);
  }
  40% {
    transform: translate(495px, 20px);
    transform: rotate(10deg);
  }
  100% {
    transform: translate(495px, 20px);
    transform: rotate(10deg);
  }
}
